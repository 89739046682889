import {textColors} from "./colorPalette";

const {
  textBlack,
  textGray
} = textColors


export const fontWeights = {
  Regular: 400,
  Medium: 500,
  Semibold: 600,
  Bold: 700
}

export const headers = {
  h1: {
    fontSize: "50px",
    lineHeight: "75px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h1m: {
    fontSize: "24px",
    lineHeight: "36px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h2: {
    fontSize: "40px",
    lineHeight: "60px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack

  },
  h2m: {
    fontSize: "18px",
    lineHeight: "27px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h3: {
    fontSize: "20px",
    lineHeight: "30px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h3m: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h4: {
    fontSize: "14px",
    lineHeight: "22px",
    fontWeight: fontWeights.Medium,
    margin: 0,
    color: textBlack
  },
  h4m: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
  h5: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: fontWeights.Medium,
    margin: 0,
    color: textBlack
  },
  h5m: {
    fontSize: "8px",
    lineHeight: "16px",
    fontWeight: fontWeights.Bold,
    margin: 0,
    color: textBlack
  },
}

export const paragraphs = {
  // PARAGRAPH LARGE
  paragraph1: {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: fontWeights.Regular,
    margin: 0,
    color: textGray
  },
  paragraph1m: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: fontWeights.Regular,
    margin: 0,
    color: textGray
  },
  paragraph2: {
    fontSize: "14px",
    lineHeight: "21px",
    fontWeight: fontWeights.Regular,
    margin: 0,
    color: textBlack
  },
  paragraph3: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: fontWeights.Medium,
    margin: 0,
    color: textGray
  },
  paragraph3regular: {
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: fontWeights.Regular,
    margin: 0,
    color: textGray
  },
}


