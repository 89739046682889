import React, {useEffect} from 'react';
import styled from 'styled-components'
import {H2, H3, H4} from "../../components/reusable/styling/headers";
import Button from "../../components/reusable/Button";
import {Paragraph1} from "../../components/reusable/styling/paragraphs";
import {accentColors, backgroundColors, textColors} from "../../components/reusable/styling/colorPalette";
import ProjectCard from "../../components/reusable/ProjectCard";
import projects from "../../projects.json";
import Certificates from "../../components/reusable/Certificates";
import Gallery from "../../components/reusable/Gallery";
import useWindowSize from "../../utils/useWindowSize";
import {device, size} from "../../components/reusable/styling/breakpoints";
import {ProjectType} from "../projekt";

const {
  backgroundLightGray,
  backgroundRed,
} = backgroundColors

const {
  accentWhite
} = accentColors

const {
  textWhite
} = textColors

const typedProjects: ProjectType[] = projects

type indexProps = {}

export default function Gradnje(props: indexProps): JSX.Element {

  const [windowWidth] = useWindowSize()

  let groupSize = 6
  if (windowWidth < size.tablet) {
    groupSize = 1
  } else if (windowWidth < size.tabletHorizontal) {
    groupSize = 4
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const keyPoints = [
    {
      title: "Načrtovanje in projektiranje",
      description: "Sodelovanje pri oblikovanju hiše po željah stranke, izdelava arhitekturnih načrtov ter pridobivanje gradbenih dovoljenj."
    },
    {
      title: "Izvedba gradnje",
      description: "Gradnja od začetka do konca, vključno z vsemi zemeljskimi deli, talno ploščo in gradnjo montažne hiše – vse do faze, ko je hiša primerna za vselitev."
    },
    {
      title: "Pohištvo in interier",
      description: "Stranki svetujemo izris interierja, po katerem se objektu doda tudi ustrezno visokokakovostno izdelano pohištvo po željah stranke in skladno z enotnim izbranim dizajnom na podlagi izrisov."
    },
    {
      title: "Kakovost in nadzor",
      description: "Nadzor nad kakovostjo uporabljenih materialov in izvedbe gradbenih del, zagotavljanje skladnosti z načrti ter redno poročanje stranki o napredku."
    },
    {
      title: "Cena gradnje",
      description: "Gradimo projekte »na ključ«. To pomeni, da se dogovorjena cena za dogovorjeni obseg projekta in faznost ne spremenita med gradnjo."
    },
    {
      title: "Terminski načrt gradnje",
      description: "Čas gradnje montažne hiše je veliko krajši kot pri klasični gradnji. Ob tem se tudi izognemo nevarnostim na finančnem trgu – dvigu cen surovin. Povprečen čas gradnje montažnih hiš od izdanega gradbenega dovoljenja je nekje 4–6 mesecev."
    },
  ]


  return (
    <Wrapper>
      <RowWrapper>
        <H2>
          Gradnje
        </H2>
        <Button
          text={"Pošlji povpraševanje"}
          icon={"sms"}
          to={"mailto:info@graviz.si"}
          background={backgroundRed}
          color={textWhite}
          iconColor={accentWhite}
          borderColor={"transparent"}
        />
      </RowWrapper>
      <Description>
        Ko govorimo o gradnji hiše, je to navadno eden največjih investicijskih projektov posameznika ali družine v življenju. Gradnje hiše se ne lotevamo pogosto, večina ljudi se je loti le enkrat v življenju. <br/> <br/>
        Gradnja hiše ponavadi predstavlja tudi največjo finančno investicijo posameznika ali družine. <br/> <br/>
        Pri tem je še kako pomembno, da gradnjo zaupamo podjetju oziroma osebi, ki ji resnično zaupamo in bo imela navedena dejstva vedno pred očmi. <br/> <br/>
        Rezultat gradnje mora biti hiša, ki bo varna, energetsko učinkovita, predvsem pa finančno vzdržna in skladna z vašim proračunom. Podjetje, ki jo gradi, mora v celoti izpolniti vaša pričakovanja – tako na kakovostni, oblikovni, rokovni kot finančni ravni. <br/> <br/>
        Izvajalec mora s svojim kakovostnim in terminsko usklajenim delom skladno s projektno dokumentacijo vedno znova dokazovati investitorju, da je njegova odločitev o izboru izvajalca pravilna, in investitorjevo zaupanje nagraditi s kakovostnim objektom, zgrajenim v roku in po dogovorjeni ceni.
      </Description>
      <SectionTitle>
        Ključne točke
      </SectionTitle>
      <KeyPointsWrapper>
        <KeyPoints>
          {keyPoints.map((keyPoint, index) => (
            <KeyPointCard
              key={index}
            >
              <H4>
                {keyPoint.title}
              </H4>
              <CardDescription>
                {keyPoint.description}
              </CardDescription>
            </KeyPointCard>
          ))}
        </KeyPoints>
      </KeyPointsWrapper>

      <SectionTitle>
        Referenčni projekti
      </SectionTitle>
        <Gallery
          groupSize={groupSize}
          items={typedProjects.filter(project => project.category === "Gradnje")}
          callback={(project) =>
            <ProjectCard
            key={project.id}
            project={project as any}
            showCategory={false}
            to={`/gradnje/projekt/${project.id}`}
            />
          }
        />
      <SectionTitle>
        Naši certifikati
      </SectionTitle>
      <Certificates/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.mobile}) {
    padding: 20px 20px 0 20px;
  };
  max-width: 1400px;
  width: 100%;
  padding: 60px 70px 0 70px;
  box-sizing: border-box;
  margin: 0 auto 100px auto;
`

const RowWrapper = styled.div`
  @media (${device.mobile}) {
    margin: 0 0 40px 0;
  };
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 40px 0;
`

const Description = styled(Paragraph1)`
  max-width: 827px;
  width: 100%;
`

const CardDescription = styled(Paragraph1)`
  width: 100%;
  margin: 10px 0 0 0;
`

const KeyPoints = styled.div`
  @media (${device.tabletHorizontal}) {
    grid-template-columns: 393px 393px;
    column-gap: 40px;
    row-gap: 40px;
  };
  @media (${device.tablet}) {
    grid-template-columns: 50% 50%;
    column-gap: 20px;
    row-gap: 20px;
  };
  @media (${device.mobile}) {
    grid-template-columns: auto;
  };
  display: grid;
  grid-template-columns: 33% 33% 33%;
  column-gap: 40px;
  row-gap: 40px;
`

const KeyPointsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`

const KeyPointCard = styled.div`
  @media (${device.tabletHorizontal}) {
    margin: 0 20px 20px 0 !important;
  };
  @media (${device.mobile}) {
    padding: 20px;
    height: fit-content;
    min-height: initial;
    width: 320px;
    margin: 0 0 20px 0 !important;
  };
  width: 100%;
  max-width: 393px;
  min-height: 200px;
  border-radius: 10px;
  background: ${backgroundLightGray};
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
`

const SectionTitle = styled(H3)`
  @media (${device.mobile}) {
    margin: 40px 0 0 0;
  };
  margin: 60px 0 0 0;
`
