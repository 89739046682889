import React from 'react';
import {StyleSheetManager} from 'styled-components'
import {ProjectType} from "../projekt";
import Project from "../../components/project";
import GlobalStyle from "../../utils/GlobalStyle";

type ProjectPreviewProps = {
  entry: any;
  widgetFor: any
}

export default function ProjectPreview(props: ProjectPreviewProps): JSX.Element {

  const { entry } = props

  const gallery = entry.getIn(['data', 'gallery'])

  const images = gallery?._tail?.array

  const project: ProjectType = {
    title: entry.getIn(['data', 'title']),
    id: entry.getIn(['data', 'id']),
    date: entry.getIn(['data', 'date']),
    description: entry.getIn(['data', 'description']),
    disclaimer: entry.getIn(['data', 'disclaimer']),
    active: entry.getIn(['data', 'active']),
    gallery: images || [],
    category: entry.getIn(['data', 'category'])
  }

  const iframe: HTMLIFrameElement | null = document.querySelector('#nc-root iframe');
  const iframeHeadElem = iframe ? iframe.contentDocument?.head : null;

  return (
    <StyleSheetManager
      target={iframeHeadElem as HTMLElement}
    >
      <GlobalStyle/>
      <Project
        project={project}
        groupSize={4}
        displayButton={false}
        lazyLoadImage={false}
      />
    </StyleSheetManager>
  )
}

