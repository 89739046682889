import React from 'react';
import styled from 'styled-components'
import {backgroundColors, textColors} from "./reusable/styling/colorPalette";
import {Paragraph3} from "./reusable/styling/paragraphs";
import {device} from "./reusable/styling/breakpoints";
import {fontWeights} from "./reusable/styling/typography";

const {
  backgroundRed
} = backgroundColors

const {
  textWhite
} = textColors

type NotificationProps = {
  text: string;
  active: boolean
  isMenuOpen: boolean;
  nHeight: number
}

export default function Notification(props: NotificationProps): JSX.Element | null {

  const {
    text,
    active,
    isMenuOpen,
    nHeight
  } = props

  if (!active) return null


  return (
    <Wrapper
      id={"notificationWrapper"}
      $isMenuOpen={isMenuOpen}
      $maxHeight={nHeight ? `${nHeight}px` : "auto"}
    >
      <Text>
        {text}
      </Text>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  $isMenuOpen: boolean;
  $maxHeight: string
}>`
  @media (${device.mobile}) {
    padding: ${props => props.$isMenuOpen ? "0 20px" : "6px 20px"};
  };
  width: 100%;
  background: ${backgroundRed};
  padding: ${props => props.$isMenuOpen ? "0 70px" : "6px 70px"};
  box-sizing: border-box;
  max-height: ${props => props.$isMenuOpen ? "0" : props.$maxHeight};
  transition: all 0.3s ease-in-out;
`

const Text = styled(Paragraph3)`
  width: 100%;
  color: ${textWhite};
  font-weight: ${fontWeights.Regular};
  text-align: center;
`