import React, {useEffect} from 'react';
import styled from 'styled-components'
import {H2, H3, H4} from "../../components/reusable/styling/headers";
import Button from "../../components/reusable/Button";
import {Paragraph1} from "../../components/reusable/styling/paragraphs";
import {backgroundColors, textColors, accentColors} from "../../components/reusable/styling/colorPalette";
import ProjectCard from "../../components/reusable/ProjectCard";
import projects from "../../projects.json";
import Certificates from "../../components/reusable/Certificates";
import Gallery from "../../components/reusable/Gallery";
import {device, size} from "../../components/reusable/styling/breakpoints";
import useWindowSize from "../../utils/useWindowSize";
import {ProjectType} from "../projekt";

const {
  backgroundLightGray,
  backgroundRed,
} = backgroundColors

const {
  accentWhite
} = accentColors

const {
  textWhite
} = textColors

type indexProps = {}

const typedProjects: ProjectType[] = projects


export default function Nadzori(props: indexProps): JSX.Element {

  const [windowWidth] = useWindowSize()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const keyPoints = [
    {
      title: "Načrtovanje in projektiranje",
      description: "Sodelovanje pri izdelavi načrtov, pridobivanje potrebnih dovoljenj, inženiring in priprava celotne dokumentacije za gradnjo."
    },
    {
      title: "Izbira izvajalcev",
      description: "Pomoč pri izbiri pravih izvajalcev in podizvajalcev za posamezne faze gradnje."
    },
    {
      title: "Nadzor gradnje",
      description: "Redno spremljanje poteka gradnje, izvajanja dogovorjenih postopkov, nadzor kakovosti materialov in dela, ter zagotavljanje, da se dela izvajajo v skladu z načrti."
    },
    {
      title: "Upravljanje proračuna",
      description: "Nadzor nad finančnimi sredstvi, spremljanje stroškov gradnje, vodenje gradbenega proračuna."
    },
    {
      title: "Komunikacija in poročanje",
      description: "Redno obveščanje strank o napredku gradnje in morebitnih spremembah, priprava poročil o napredku in stroških."
    }
  ]

  return (
    <Wrapper>
      <RowWrapper>
        <H2>
          Nadzori
        </H2>
        <Button
          text={"Pošlji povpraševanje"}
          icon={"sms"}
          to={"mailto:info@graviz.si"}
          background={backgroundRed}
          color={textWhite}
          iconColor={accentWhite}
          borderColor={"transparent"}
        />
      </RowWrapper>
      <Description>
        Storitve nadzora in vodenja investicij gradnje objektov za stranke so temeljnega pomena pri zagotavljanju uspešnega in brezhibnega poteka gradbenih projektov. Ta vrsta storitev vključuje celoten spekter nalog in odgovornosti, ki so ključne za uresničitev gradbenega načrta stranke.
      </Description>
      <SectionTitle>
        Ključne točke
      </SectionTitle>
      <KeyPointsWrapper>
        <KeyPoints>
          {keyPoints.map((keyPoint, index) => (
            <KeyPointCard
              key={index}
            >
              <H4>
                {keyPoint.title}
              </H4>
              <CardDescription>
                {keyPoint.description}
              </CardDescription>
            </KeyPointCard>
          ))}
        </KeyPoints>
      </KeyPointsWrapper>
      <SectionTitle>
        Referenčni projekti
      </SectionTitle>
      <Gallery
        groupSize={windowWidth >= size.mobile ? 6 : 1}
        items={typedProjects.filter(project => project.category === "Nadzori")}
        callback={(project) =>
          <ProjectCard
            key={project.id}
            project={project as any}
            showCategory={false}
            to={`/nadzori/projekt/${project.id}`}
          />
        }
      />
      <SectionTitle>
        Naši certifikati
      </SectionTitle>
      <Certificates/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.mobile}) {
    padding: 20px 20px 0 20px;
  };
  max-width: 1400px;
  width: 100%;
  padding: 60px 70px 0 70px;
  box-sizing: border-box;
  margin: 0 auto 100px auto;
`

const RowWrapper = styled.div`
  @media (${device.mobile}) {
    margin: 0 0 40px 0;
  };
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 40px 0;
`

const Description = styled(Paragraph1)`
  max-width: 827px;
  width: 100%;
`

const CardDescription = styled(Paragraph1)`
  width: 100%;
  margin: 10px 0 0 0;
`

const KeyPoints = styled.div`
  @media (${device.tabletHorizontal}) {
    grid-template-columns: 393px 393px;
    column-gap: 40px;
    row-gap: 40px;
  };
  @media (${device.tablet}) {
    grid-template-columns: auto auto;
    column-gap: 20px;
    row-gap: 20px;
  };
  @media (${device.mobile}) {
    grid-template-columns: auto;
  };
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 40px;
  row-gap: 40px;
`

const KeyPointsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 40px 0 0 0;
`

const KeyPointCard = styled.div`
  @media (${device.tabletHorizontal}) {
    margin: 0 20px 20px 0 !important;
  };
  @media (${device.mobile}) {
    padding: 20px;
    height: fit-content;
    width: 320px;
    margin: 0 0 20px 0 !important;
  };
  width: 100%;
  max-width: 393px;
  height: 155px;
  border-radius: 10px;
  background: ${backgroundLightGray};
  padding: 20px;
  box-sizing: border-box;
  margin: 0;
`

const SectionTitle = styled(H3)`
  @media (${device.mobile}) {
    margin: 40px 0 0 0;
  };
  margin: 60px 0 0 0;
`
