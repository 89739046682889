export const brandColors = {
  black: "#000000",
  lightGray: "#EEEEEE",
  gray: "#7A7A7A",
  white: "#FFFFFF",
  red: "#C6000C"
}

export const backgroundColors = {
  backgroundBlack: "#000000",
  backgroundGray: "#7A7A7A",
  backgroundLightGray: "#EEEEEE",
  backgroundWhite: "#FFFFFF",
  backgroundRed: "#C6000C"

}

export const accentColors = {
  accentBlack: "#000000",
  accentGray: "#7A7A7A",
  accentLightGray: "#EEEEEE",
  accentWhite: "#FFFFFF",
  accentRed: "#C6000C"
}

export const textColors = {
  textBlack: "#000000",
  textGray: "#7A7A7A",
  textLightGray: "#EEEEEE",
  textWhite: "#FFFFFF",
  textRed: "#C6000C"
}

export const systemColors = {
  systemGreen: "#33B65E",
  systemOrange: "#FEA444",
  systemRed: "#DD3C43"
}

export function hexToRgbA(hex: string, opacity: number): string{
  let c: any;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
    c= hex.substring(1).split('');
    if(c.length === 3){
      c= [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c= '0x'+c.join('');
    return  `rgba(${[(c>>16)&255, (c>>8)&255, c&255].join(',')},${opacity})`
  }
  throw new Error('Bad Hex');
}
