
export default function convertFileNameToMin(path: string) {
  const fileName = path.split('/').pop();

  if (!fileName) return null

  const fileNameParts = fileName.split('.');
  const baseName = fileNameParts[0];

  return `${baseName}-min.jpeg`;
}
