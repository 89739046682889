"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _decapCmsBackendGitlab = require("decap-cms-backend-gitlab");
var _decapCmsLibUtil = require("decap-cms-lib-util");
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
class API extends _decapCmsBackendGitlab.API {
  constructor(config) {
    super(config);
    _defineProperty(this, "tokenPromise", void 0);
    _defineProperty(this, "withAuthorizationHeaders", async req => {
      const token = await this.tokenPromise();
      return _decapCmsLibUtil.unsentRequest.withHeaders({
        Authorization: `Bearer ${token}`
      }, req);
    });
    _defineProperty(this, "hasWriteAccess", () => Promise.resolve(true));
    this.tokenPromise = config.tokenPromise;
    this.commitAuthor = config.commitAuthor;
    this.repoURL = '';
  }
}
exports.default = API;