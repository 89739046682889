import React from 'react';
import Header from "./components/Header";
import styled from "styled-components";
import {Outlet} from "react-router-dom";
import Footer from "./components/Footer";
function App() {

  return (
    <Wrapper>
      <Header/>
      <BodyWrapper>
        <Outlet/>
      </BodyWrapper>
      <Footer/>
    </Wrapper>
  )
}

export default App;

const Wrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  position: relative;
`

const BodyWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 338px);
`
