import React, { useEffect } from 'react';
import CMS from 'decap-cms-app';
import ProjectPreview from "./ProjectPreview";
import CertificatePreview from "./CertificatePreview";

const CMSAdmin = () => {
  useEffect(() => {
    CMS.init({
      config: {
        "backend": {
          "name": "git-gateway",
          "branch": "master"
        },
        "media_folder": "public/images/uploads",
        "public_folder": "/images/uploads",
        "collections": [
          {
            "name": "projekt",
            "label": "Projekt",
            "folder": "projekti",
            "create": true,
            "slug": "{{year}}-{{month}}-{{day}}-{{slug}}",
            "fields": [
              {
                "label": "Naslov",
                "name": "title",
                "widget": "string"
              },
              {
                "label": "Publish Date",
                "name": "date",
                "widget": "datetime"
              },
              {
                "label": "Opis",
                "name": "description",
                "widget": "markdown"
              },
              {
                "label": "Aktualen",
                "name": "active",
                "widget": "boolean",
                "default": true
              },
              {
                "label": "Opomba",
                "name": "disclaimer",
                "widget": "string",
                "required": false
              },
              {
                "label": "Galerija",
                "name": "gallery",
                "widget": "list",
                "label_singular": "image",
                "summary": "{{fields.image}}",
                "field": {
                  "label": "Image",
                  "name": "image",
                  "widget": "image"
                }
              },
              {
                "label": "Kategorija",
                "name": "category",
                "widget": "select",
                "options": [
                  "Gradnje",
                  "Nadzori"
                ]
              }
            ]
          },
          {
            "name": "certifikat",
            "label": "Certifikat",
            "folder": "certifikati",
            "create": true,
            "slug": "{{year}}-{{month}}-{{day}}-{{slug}}",
            "fields": [
              {
                "label": "Naslov",
                "name": "title",
                "widget": "string"
              },
              {
                "label": "Slika",
                "name": "image",
                "widget": "image"
              },
              {
                "label": "Datoteka",
                "name": "file",
                "widget": "file"
              }
            ]
          },
          {
            "name": "obvestilo",
            "label": "Obvestilo",
            "folder": "obvestilo",
            "create": false,
            "delete": false,
            "slug": "{{year}}-{{month}}-{{day}}-{{slug}}",
            "fields": [
              {
                "label": "Besedilo",
                "name": "text",
                "widget": "string"
              },
              {
                "label": "Aktivno",
                "name": "active",
                "widget": "boolean",
                "default": true
              }
            ]
          }
        ]
      }
    });

    CMS.registerPreviewTemplate('projekt', ProjectPreview);
    CMS.registerPreviewTemplate('certifikat', CertificatePreview);
  }, []);

  return <div id="nc-root" />;
};

export default CMSAdmin;