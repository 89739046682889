import React from 'react';
import styled from 'styled-components'
import {H5} from "./reusable/styling/headers";
import {CertificateType} from "./reusable/Certificates";

type CertificateCardProps = {
  certificate: CertificateType
  handleFullScreenClick?(file: string): void
}

export default function CertificateCard(props: CertificateCardProps): JSX.Element {

  const {
    certificate,
    handleFullScreenClick
  } = props

  return (
    <Wrapper
      onClick={() => {
        if (!handleFullScreenClick) return

        if (certificate.file.includes(".pdf")) {
          window.open(certificate.file);
        } else {
          handleFullScreenClick(certificate.file)
        }
      }}
    >
      <ImageWrapper>
        <Image
          src={certificate.image}
          alt={certificate.image}
        />
      </ImageWrapper>
      <H5>
        {certificate.title}
      </H5>
    </Wrapper>
  )
}



const Wrapper = styled.div`
  height: 158px;
  min-width: 177px;
  max-width: 177px;
  margin: 0 20px;
  cursor: pointer;
  &:is(:first-child) {
    margin: 0 20px 0 0;
  };
  &:is(:last-child) {
    margin: 0 0 0 20px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 120px;
  margin: 0 0 20px 0;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`
