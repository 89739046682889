import {createGlobalStyle} from 'styled-components'



const GlobalStyle = createGlobalStyle<{ $whiteColor?: boolean; }>`
  * {
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
    font-family: Poppins, sans-serif;
    // CSS you want global. 
  }
`

export default GlobalStyle