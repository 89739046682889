import React from 'react';
import styled from 'styled-components'
import certificates from "../../certificates.json";
import {hexToRgbA, backgroundColors} from "./styling/colorPalette";
import CertificateCard from "../CertificateCard";

const typedCertificates: CertificateType[] = certificates

const {backgroundGray} = backgroundColors

type CertificatesProps = {}

export type CertificateType = {
  title: string,
  image: string,
  file: string
}

export default function Certificates(props: CertificatesProps): JSX.Element {

  const [fullScreenImage, setFullScreenImage] = React.useState<string | undefined>();
  function handleFullScreenClick(image?: string) {
    const element = document.body
    if (element) {
      if (image) {
        element.style.overflow = "hidden"
      } else {
        element.style.overflow = "scroll"
      }
    }
    setFullScreenImage(image)
  }


  return (
    <Wrapper>
      {typedCertificates.map((certificate, index) => (
        <CertificateCard
          key={index}
          certificate={certificate}
          handleFullScreenClick={handleFullScreenClick}
        />
      ))}
      <FullScreenView
        onClick={() => handleFullScreenClick(undefined)}
        $open={!!fullScreenImage}
      >
        <FullScreenImage
          src={fullScreenImage}
        />
      </FullScreenView>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  width: 100%;
  display: flex;
  overflow-x: scroll;
  margin: 40px 0 0 0;
`

const FullScreenImage = styled.img`
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  object-fit: contain;
  cursor: pointer;
`


const FullScreenView = styled.div<{
  $open: boolean
}>`
  display: ${({$open}) => $open ? 'flex' : 'none'};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: ${hexToRgbA(backgroundGray, 0.9)};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  cursor: pointer;
`