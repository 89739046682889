import React, {useEffect} from 'react';
import styled from 'styled-components'
import Carousel from "../../components/Carousel";
import ProjectCard from "../../components/reusable/ProjectCard";
import projects from "../../projects.json";
import {H3} from "../../components/reusable/styling/headers";
import Gallery from "../../components/reusable/Gallery";
import useWindowSize from "../../utils/useWindowSize";
import {size, device} from "../../components/reusable/styling/breakpoints";
import {ProjectType} from "../projekt";

type indexProps = {}

const typedProjects: ProjectType[] = projects


export default function Domov(props: indexProps): JSX.Element {

  const [windowWidth] = useWindowSize()

  let groupSize = 3
  if (windowWidth < size.mobile) {
    groupSize = 1
  } else if (windowWidth < size.tabletHorizontal) {
    groupSize = 2
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <Carousel/>
      <ActiveProjects>
        <H3>
          Aktualni projekti
        </H3>
        <Gallery
          items={typedProjects.filter(project => project.active)}
          groupSize={groupSize}
          callback={(project) =>
            <ProjectCard
              key={project.id}
              project={project as any}
              to={`/${project.category.toLocaleLowerCase()}/projekt/${project.id}`}
            />
          }
        />
      </ActiveProjects>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`

const ActiveProjects = styled.div`
  @media (${device.tablet}) {
    padding: 40px 20px 0 20px;
    margin: 0 auto 76px auto;
  };
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
  padding: 60px 70px 0 70px;
  margin: 0 auto 176px auto;
`
