import React from 'react';
import styled from 'styled-components'
import {backgroundColors, accentColors} from "./styling/colorPalette";
import {H4} from "./styling/headers";
import {Paragraph3} from "./styling/paragraphs";
import {Link} from "react-router-dom"
import {device} from "./styling/breakpoints";
import AsyncImage from "./AsyncImage";
import convertFileNameToMin from "../../utils/convertFileNameToMin";

const {
  backgroundWhite,
  backgroundLightGray
} = backgroundColors

const {
  accentBlack
} = accentColors

type ProjectProps = {
  showCategory?: boolean;
  to: string;
  project: {
    id: number;
    title: string;
    description: string;
    disclaimer: string;
    category: string;
    active: boolean;
    gallery: string[];
  }
}

export default function ProjectCard(props: ProjectProps): JSX.Element {

  const {
    showCategory = true,
    to,
    project: {
      category,
      title,
      gallery,
      disclaimer
    }
  } = props

  return (
    <Wrapper>
      <Link
        to={to}
      >
        <ImageWrapper>
          <AsyncImage
            id={`projectCardImage-${gallery[0]}`}
            imagePath={gallery[0]}
            minImagePath={`/images/uploadsmin/${convertFileNameToMin(gallery[0])}`}
          />
        </ImageWrapper>
      </Link>

      {showCategory &&
        <Category>
          <H4>
            {category}
          </H4>
        </Category>
      }
      <Title>
        {title}
      </Title>
      <Subtitle>
        {disclaimer}
      </Subtitle>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.tabletHorizontal}) {
    max-width: 270px;
  };
  @media (${device.mobile}) {
    max-width: 320px;
  };
  width: 100%;
  max-width: 393px;
  position: relative;
`

const ImageWrapper = styled.div`
  @media (${device.tabletHorizontal}) {
    height: 240px;
  };
  @media (${device.mobile}) {
    height: 290px;
  };
  width: 100%;
  height: 320px;
  background-color: ${backgroundLightGray};
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
`


const Category = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 32px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  background: ${backgroundWhite};
  border: 1px solid ${accentBlack};
  border-radius: 5px;
`

const Title = styled(H4)`
  margin: 20px 0 0 0;
`

const Subtitle = styled(Paragraph3)`
  margin: 10px 0 0 0;
`