import React from 'react';
import {StyleSheetManager} from 'styled-components'
import GlobalStyle from "../../utils/GlobalStyle";
import CertificateCard from "../../components/CertificateCard";
import {CertificateType} from "../../components/reusable/Certificates";

type CertificatePreviewProps = {
  entry: any;
  widgetFor: any
}


export default function CertificatePreview(props: CertificatePreviewProps): JSX.Element {

  const { entry } = props


  const certificate: CertificateType = {
    title: entry.getIn(['data', 'title']),
    image: entry.getIn(['data', 'image']),
    file: entry.getIn(['data', 'file']),
  }

  const iframe: HTMLIFrameElement | null = document.querySelector('#nc-root iframe');
  const iframeHeadElem = iframe ? iframe.contentDocument?.head : null;

  return (
    <StyleSheetManager
      target={iframeHeadElem as HTMLElement}
    >
      <GlobalStyle/>
      <CertificateCard
        certificate={certificate}
      />
    </StyleSheetManager>
  )
}
