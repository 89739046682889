import React from 'react';
import styled from 'styled-components'
import Icon from "./Icon";
import {Link} from "react-router-dom";

type SocialsProps = {}

export default function Socials(props: SocialsProps): JSX.Element {

  return (
    <Wrapper>
      <LinkComponent
        to={"https://www.instagram.com/gravizhise/"}
        target={"_blank"}
        $margin={"0 20px 0 0"}
      >
        <Icon
          icon={"instagram"}
          width={"24px"}
          height={"24px"}
        />
      </LinkComponent>
      <LinkComponent
        target={"_blank"}
        to={"https://www.facebook.com/profile.php?id=100063840768792"}
        $margin={"0 20px 0 0"}
      >
        <Icon
          icon={"facebook"}
          width={"24px"}
          height={"24px"}
        />
      </LinkComponent>
      <LinkComponent
        target={"_blank"}
        to={""}
      >
        <Icon
          icon={"youtube"}
          width={"24px"}
          height={"24px"}
        />
      </LinkComponent>

    </Wrapper>
  )
}

const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const LinkComponent = styled(Link)<{
  $margin?: string;
}>`
  margin: ${props => props.$margin};
  width: 24px;
  height: 24px;
`

