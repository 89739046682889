import styled from "styled-components";
import {headers} from "./typography";
import {device} from "./breakpoints";

const {
  h1,
  h1m,
  h2,
  h2m,
  h3,
  h3m,
  h4,
  h4m,
  h5,
  h5m,
} = headers

export const H1 = styled.h1`
  @media (${device.mobile}) {
    font-size: ${h1m.fontSize};
    line-height: ${h1m.lineHeight};
  };
  font-size: ${h1.fontSize};
  line-height: ${h1.lineHeight};
  font-weight: ${h1.fontWeight};
  margin: ${h1.margin};
  color: ${h1.color};
`

export const H2 = styled.h2`
  @media (${device.mobile}) {
    font-size: ${h2m.fontSize};
    line-height: ${h2m.lineHeight};
  };
  font-size: ${h2.fontSize};
  line-height: ${h2.lineHeight};
  font-weight: ${h2.fontWeight};
  margin: ${h2.margin};
  color: ${h2.color};
`

export const H3 = styled.h3`
  @media (${device.mobile}) {
    font-size: ${h3m.fontSize};
    line-height: ${h3m.lineHeight};
  };
  font-size: ${h3.fontSize};
  line-height: ${h3.lineHeight};
  font-weight: ${h3.fontWeight};
  margin: ${h3.margin};
  color: ${h3.color};
`
export const H4 = styled.h4`
  @media (${device.mobile}) {
    font-size: ${h4m.fontSize};
    line-height: ${h4m.lineHeight};
  };
  font-size: ${h4.fontSize};
  line-height: ${h4.lineHeight};
  font-weight: ${h4.fontWeight};
  margin: ${h4.margin};
  color: ${h4.color};
`
export const H5 = styled.h5`
  @media (${device.mobile}) {
    font-size: ${h5m.fontSize};
    line-height: ${h5m.lineHeight};
  };
  font-size: ${h5.fontSize};
  line-height: ${h5.lineHeight};
  font-weight: ${h5.fontWeight};
  margin: ${h5.margin};
  color: ${h5.color};
`
