import React from 'react';
import styled from 'styled-components'
import {device} from "./reusable/styling/breakpoints";
import Button from "./reusable/Button";
import {textColors, accentColors, backgroundColors} from "./reusable/styling/colorPalette";
import {Link} from "react-router-dom";
import {Paragraph2} from "./reusable/styling/paragraphs";

const {textGray, textWhite} = textColors
const {backgroundRed} = backgroundColors
const {accentWhite, accentLightGray, accentBlack} = accentColors

type FooterProps = {}

export default function Footer(props: FooterProps): JSX.Element {


  return (
    <Wrapper>
      <InnerWrapper>
        <LeftColumnWrapper>
          <Link
            to={"/"}
          >
            <Logo
              src={"/logo.svg"}
              alt={"Rezervato logo"}
            />
          </Link>
          <PMediumMediumText>
            Gradnje z vizijo.
          </PMediumMediumText>
          <InfoText>
            Graviz d.o.o.
            Brode 24H
            3305, Vransko
          </InfoText>
          <PMediumMediumText>
            © Graviz d.o.o. {new Date().getFullYear()}, Vse pravice pridržane.
          </PMediumMediumText>
        </LeftColumnWrapper>

        <RightColumnWrapper>
          <TOSWrapper>
            <TOSLink
              to={"/"}
              style={{textDecoration: "none"}}
              $padding={"0 50px 0 0"}
            >
              <Paragraph2>
                Politika zasebnosti
              </Paragraph2>
            </TOSLink>
            <TOSLink
              to={"/"}
              style={{textDecoration: "none"}}
            >
              <Paragraph2>
                Pogoji poslovanja
              </Paragraph2>
            </TOSLink>
          </TOSWrapper>
          <ButtonsWrapper>
            <MobileButtonWrapper>
              <Button
                icon={"call"}
                to={"tel:+386 41 747 851"}
                hoverBackground={backgroundRed}
                hoverColor={textWhite}
                hoverIconColor={accentWhite}
                hoverBorderColor={"transparent"}
                margin={"0 20px 0 0"}
                ariaLabel={"Call"}
              />
            </MobileButtonWrapper>
            <MobileButtonWrapper>
              <Button
                icon={"sms"}
                to={"mailto:info@graviz.si"}
                hoverBackground={backgroundRed}
                hoverColor={textWhite}
                hoverIconColor={accentWhite}
                hoverBorderColor={"transparent"}
                ariaLabel={"Email"}
              />
            </MobileButtonWrapper>
            <DesktopButtonWrapper>
              <Button
                icon={"call"}
                to={"tel:+386 41 747 851"}
                text={"041 747 851"}
                hoverBorderColor={"transparent"}
                hoverBackground={backgroundRed}
                hoverColor={textWhite}
                hoverIconColor={accentWhite}
                margin={"0 20px 0 0"}
              />
            </DesktopButtonWrapper>
            <DesktopButtonWrapper>
              <Button
                icon={"sms"}
                to={"mailto:info@graviz.si"}
                text={"info@graviz.si"}
                hoverBorderColor={"transparent"}
                hoverBackground={backgroundRed}
                hoverColor={textWhite}
                hoverIconColor={accentWhite}
              />
            </DesktopButtonWrapper>
          </ButtonsWrapper>
        </RightColumnWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.mobile}) {
    padding: 30px 0 40px 0;
  };
  width: 100%;
  padding: 30px 70px 50px 70px;
  box-sizing: border-box;
`



const InnerWrapper = styled.div`
  @media (${device.mobile}) {
    flex-direction: column-reverse;
  };
  padding: 30px 0 0 0;
  width: 100%;
  border-top: 1px solid ${accentBlack};
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  
`

const Logo = styled.img`
  height: 28px;
  width: 128px;
`

const TOSWrapper = styled.div`
  @media (${device.mobile}) {
    padding: 0 20px 30px 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    height: 94px;
    border-bottom: 1px solid ${accentLightGray};
  };
  padding: 0;
  border: none;
  width: initial;
  height: initial;
  display: flex;
  align-items: center;
  flex-direction: row;
`

const TOSLink = styled(Link)<{
  $padding?: string
}>`
  @media (${device.mobile}) {
    padding: 0;
  };
  padding: ${({$padding}) => $padding ? $padding : "0"};
  text-decoration: none;
`

const LeftColumnWrapper = styled.div`
  @media (${device.mobile}) {
    padding: 20px 20px 0 20px;
    box-sizing: border-box;
    border-top: 1px solid ${accentLightGray};
  };
  border-top: none;
  padding: 0;
  display: flex;
  flex-direction: column;
`

const RightColumnWrapper = styled.div`
  @media (${device.mobile}) {
    padding: 0 0 20px 0;
    box-sizing: border-box;
    align-items: flex-start;
  };
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const PMediumMediumText = styled(Paragraph2)`
  margin: 20px 0 0 0;
  max-width: 302px;
`

const InfoText = styled(Paragraph2)`
  margin: 20px 0 30px 0;
  max-width: 100px;
  color: ${textGray};
`


const ButtonsWrapper = styled.div`
  @media (${device.mobile}) {
    margin: 20px 0 0 0;
    padding: 0 20px;
    box-sizing: border-box;
  };
  padding: 0;
  display: flex;
  align-items: center;
  margin: 154px 0 0 0;
`

const DesktopButtonWrapper = styled.div`
  @media (${device.tablet}) {
    display: none;
  };
  display: block;
`


const MobileButtonWrapper = styled.div`
  @media (${device.tablet}) {
    display: block;
  };
  display: none;
`