import {
  RouteObject,
  useRoutes
} from "react-router-dom";
import App from "./App";
import Domov from "./views/domov";
import Gradnje from "./views/gradnje";
import Nadzori from "./views/nadzori";
import Vizitka from "./views/vizitka";
import Projekt from "./views/projekt";
import Admin from "./views/admin"


export const Router = () => {


  const rootRoutes: RouteObject[] = [
    {
      path: "/",
      element:
        <App/>,
      children: [
        {
          index: true,
          element:
            <Domov/>,
        },
        {
          path: "domov",
          element:
            <Domov/>,
        },
        {
          path: "gradnje",
          children: [
            {
              index: true,
              element:
                <Gradnje/>,
            },
            {
              path: "projekt/:id",
              element:
                <Projekt/>,
            }
          ]
        },
        {
          path: "nadzori",
          children: [
            {
              index: true,
              element:
                <Nadzori/>,
            },
            {
              path: "projekt/:id",
              element:
                <Projekt/>,
            }
          ]
        },
        {
          path: "vizitka",
          element:
            <Vizitka/>,
        }
      ]
    },
    {
      path: "admin",
      element:
        <Admin/>,
    },
  ]

  return useRoutes(rootRoutes)
}
