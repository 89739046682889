import React from 'react';
import styled from 'styled-components'
import {backgroundColors} from "./styling/colorPalette";
import {device} from "./styling/breakpoints";

const {
  backgroundWhite,
  backgroundBlack
} = backgroundColors

type GalleryProps = {
  items: any[],
  groupSize?: number,
  callback: (item: any, index?: number) => JSX.Element,
}

export default function Gallery(props: GalleryProps): JSX.Element {

  let {
    items,
    callback,
    groupSize = 6
  } = props


  const columns = Array.from({length: groupSize > 3 ? Math.ceil(groupSize / 2) : groupSize}, (_, i) => "max-content").join(" ")
  const itemsCopy = [...items]
  const groupedItems = Array.from({length: Math.ceil(items.length / groupSize)}, () => itemsCopy.splice(0,groupSize)).filter(group => group.length > 0);
  const [currentItem, setCurrentItem] = React.useState<any>(groupedItems[0])

  function scrollTo(item: any) {
    const galleryElement = document.getElementById("gallery")
    if (!galleryElement) return
    const {
      width,
    } = galleryElement.getBoundingClientRect()
    const itemIndex = groupedItems.findIndex((galleryItem) => JSON.stringify(galleryItem) === JSON.stringify(item))

    if (itemIndex === -1) return

    galleryElement.scrollTo({
      left: itemIndex * (width + 40),
      behavior: "smooth"
    })
  }

  function scrollDots(multiplier: number) {
    const dotsElement = document.getElementById("dots")

    if (!dotsElement) return;

    const itemIndex = Math.floor(multiplier)


    if (multiplier > 2 && multiplier < groupedItems.length - 2) {
      const newScrollLeft = Math.floor(26 * (Math.floor(multiplier) - 2) + (26 * (multiplier - itemIndex)))

      if (newScrollLeft !== dotsElement.scrollLeft) {
        dotsElement.scrollTo({
          left: newScrollLeft,
          // @ts-ignore
          behavior: "instant"
        })
      }

    }
  }


  return (
    <Wrapper>
      <GalleryWrapper
        id={"gallery"}
        onScroll={(e) => {
          const {
            width,
          } = e.currentTarget.getBoundingClientRect()
          const scrollPosition = e.currentTarget.scrollLeft

          const itemIndex = Math.round(scrollPosition / (width + 40))
          setCurrentItem(groupedItems[itemIndex > groupedItems.length - 1 ? groupedItems.length - 1 : itemIndex])
          scrollDots(scrollPosition / (width + 40))
        }}
      >
        {groupedItems.map((items, index) => (
          <ItemChunkWrapper
            key={index}
            columns={columns}
          >
            {items.map((item, index) => callback(item, index))}
          </ItemChunkWrapper>
        ))}
      </GalleryWrapper>
      {groupedItems.length > 1 &&
        <DotsWrapper
          id={"dots"}
        >
          {groupedItems.map((item, index) =>
            <Dot
              key={index}
              $active={JSON.stringify(item) === JSON.stringify(currentItem)}
              onClick={() => {
                scrollTo(item)
              }}
            />
          )}
        </DotsWrapper>
      }
    </Wrapper>
  )
}


const GalleryWrapper = styled.div`
  max-width: initial;
  width: 100%;
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  &::-webkit-scrollbar {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  height: fit-content;
`


const ItemChunkWrapper = styled.div<{
  columns: string
}>`
  @media (${device.tabletHorizontal}) {
    margin: 40px 0 0 0;
    justify-content: center;
    grid-template-columns: auto auto;
    column-gap: 40px;
    row-gap: 40px;
  };
  @media (${device.mobile}) {
    margin: 20px 40px 0 0;
    justify-content: center;
    grid-template-columns: auto;
    column-gap: 0;
    row-gap: 0;
  };
  min-width: 100%;
  display: grid;
  grid-template-columns: ${props => props.columns};
  column-gap: 40px;
  row-gap: 40px;
  margin: 40px 40px 0 0;
  scroll-snap-align: start;
`


const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 20px auto 0 auto;
  max-width: 130px;
  overflow: hidden;
  scroll-behavior: smooth;
`

const Dot = styled.div<{
  $active: boolean
}>`
  min-width: 16px;
  width: 16px;
  min-height: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${props => props.$active ? backgroundBlack : backgroundWhite};
  border: 1px solid ${backgroundBlack};
  box-sizing: border-box;
  margin: 0 5px;
  cursor: pointer;
`