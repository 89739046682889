import styled from 'styled-components'
import React, {MouseEvent} from 'react'
import {accentColors} from "./styling/colorPalette";

const {accentBlack} = accentColors


export type IconProps = {
  icon: string;
  width?: string;
  height?: string;
  active?: boolean;
  margin?: string;
  iconColor?: string;
  rotate?:string;
  cursor?:string;
  fill?:string;
  onClick?(event: MouseEvent<SVGSVGElement>): void;
}

export default function Icon(
  {
    active,
    icon,
    margin,
    width,
    height,
    onClick,
    iconColor,
    rotate,
    fill,
    cursor = "pointer"
  }: IconProps
) {

  let defaultWidth = '22px'
  let defaultHeight = '22px'

  return(
    <Svg
      onClick={onClick}
      width={width || defaultWidth}
      height={height || defaultHeight}
      margin={margin}
      $active={active}
      rotate={rotate}
      cursor={cursor}
    >
      <use xlinkHref={`/icons/${icon}.svg#${icon}`} style={{color: iconColor || accentBlack, fill: fill || "none"}} />
    </Svg>
  )
}


const Svg = styled.svg<{
  width: string;
  margin?: string;
  $active?: boolean;
  color?: string;
  rotate?: string;
  cursor?: string;
}>`
  margin: ${props => props.margin || 0};
  max-width: ${props => props.width};
  min-width: ${props => props.width};
  max-height: ${props => props.width};
  min-height: ${props => props.width};
  color: ${props => props.color ? props.color : accentBlack};
  cursor: ${props => props.$active === false ? 'initial' : props.cursor};
  transform: ${props => props.rotate ? `rotate(${props.rotate})` : 'none'};
`;


