import React, {useEffect} from 'react';
import styled from 'styled-components'

type AsyncImageProps = {
  id: string;
  imagePath: string;
  minImagePath: string;
}

export default function AsyncImage(props: AsyncImageProps): JSX.Element {

  const {
    id,
    imagePath,
    minImagePath
  } = props

  useEffect(() => {
    const element: HTMLImageElement | null = document.getElementById(id) as HTMLImageElement

    if (!element) return

    const img = new Image();
    img.src = imagePath;
    // Once image is loaded replace the src of the HTML element
    img.onload = () => {
      return element.src = img.src
    };
    // eslint-disable-next-line
  }, []);

  return (
    <ImageElement
      id={id}
      src={minImagePath}
    />
  )
}

const ImageElement = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
`