import React, {useEffect} from 'react';
import styled from 'styled-components'
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import projects from "../../projects.json";
import {Paragraph1} from "../../components/reusable/styling/paragraphs";
import {device, size} from "../../components/reusable/styling/breakpoints";
import useWindowSize from "../../utils/useWindowSize";
import {backgroundColors, hexToRgbA} from "../../components/reusable/styling/colorPalette";
import Project from "../../components/project";
import capitalizeFirstLetter from "../../utils/capitalizeFirstLetter";


const {
  backgroundGray,
} = backgroundColors


type indexProps = {}

export type ProjectType = {
  id: number,
  title: string,
  date: string,
  description: string,
  disclaimer: string,
  active: boolean,
  gallery: string[],
  category: string
}

const typedProjects: ProjectType[] = projects

export default function Projekt(props: indexProps): JSX.Element | null {

  const {id} = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [windowWidth] = useWindowSize()
  const [fullScreenImage, setFullScreenImage] = React.useState<string | undefined>();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const project = typedProjects.find(project => project.id === parseInt(id as string))


  if (!project) {
    navigate("/")
    return null
  }

  const pathname = location.pathname.split('/')[1]

  let groupSize = 8
  if (windowWidth < size.mobile) {
    groupSize = 1
  } else if (windowWidth <= size.tabletHorizontal) {
    groupSize = 4
  }

  function handleFullScreenClick(image?: string) {
    const element = document.body
    if (element) {
      if (image) {
        element.style.overflow = "hidden"
      } else {
        element.style.overflow = "scroll"
      }
    }
    setFullScreenImage(image)
  }



  return (
    <Wrapper>
      <Breadcrumbs>
        <Link
          to={`/${project.category.toLowerCase()}`}
          style={{textDecoration: 'none'}}
        >
          <Breadcrumb>
            {capitalizeFirstLetter(pathname)}
          </Breadcrumb>
        </Link>
        <Breadcrumb>
          {">"}
        </Breadcrumb>
        <Breadcrumb>
          {project.title}
        </Breadcrumb>
      </Breadcrumbs>
      <Project
        project={project}
        groupSize={groupSize}
        handleFullScreenClick={handleFullScreenClick}
      />
      <FullScreenView
        onClick={() => handleFullScreenClick(undefined)}
        $open={!!fullScreenImage}
      >
        <FullScreenImage
          src={fullScreenImage}
        />
      </FullScreenView>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.mobile}) {
    padding: 0 20px;
  };
  max-width: 1400px;
  width: 100%;
  padding: 0 70px;
  box-sizing: border-box;
  margin: 0 auto 100px auto;
`

const Breadcrumbs = styled.div`
  display: flex;
`

const Breadcrumb = styled(Paragraph1)`
  margin: 0 0 0 5px;
  cursor: pointer;
`


const FullScreenImage = styled.img`
  width: calc(100vw - 20px);
  height: calc(100vh - 20px);
  object-fit: contain;
  cursor: pointer;
`


const FullScreenView = styled.div<{
  $open: boolean
}>`
  display: ${({$open}) => $open ? 'flex' : 'none'};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: ${hexToRgbA(backgroundGray, 0.9)};
  justify-content: center;
  align-items: center;
  z-index: 1000;
  top: 0;
  left: 0;
  cursor: pointer;
`