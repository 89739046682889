import React, {useState} from 'react';
import styled from 'styled-components'
import {H4} from './styling/headers'
import Icon from "./Icon";
import {accentColors, textColors, backgroundColors} from "./styling/colorPalette";
import {Link} from "react-router-dom";
import {device, size} from "./styling/breakpoints";
import useWindowSize from "../../utils/useWindowSize";

const {
  accentBlack
} = accentColors

const {
  backgroundWhite
} = backgroundColors

const {
  textBlack
} = textColors

type ButtonProps = {
  text?: string;
  icon?: string;
  onClick?(): void;
  to?: string;
  background?: string;
  border?: string;
  borderColor?: string;
  margin?: string;
  iconColor?: string;
  color?: string;
  hoverColor?: string;
  hoverIconColor?: string;
  hoverBackground?: string;
  hoverBorderColor?: string;
  ariaLabel?: string;
}

export default function Button(props: ButtonProps): JSX.Element {

  const [hover, setHover] = useState(false)


  const {
    text,
    icon,
    onClick,
    to,
    border,
    borderColor,
    background,
    margin,
    iconColor,
    color,
    hoverColor,
    hoverIconColor,
    hoverBackground,
    hoverBorderColor,
    ariaLabel,
  } = props

  const [windowWidth] = useWindowSize()


  const ButtonComponent =
    <Wrapper
      aria-label={ariaLabel}
      onClick={onClick}
      $background={background}
      $border={border}
      $color={color}
      $borderColor={borderColor}
      margin={margin}
      $hoverBackground={hoverBackground}
      $hoverBorderColor={hoverBorderColor}
      $hoverColor={hoverColor}
      onMouseEnter={() => windowWidth && windowWidth > size.mobile && setHover(true)}
      onMouseLeave={() => windowWidth && windowWidth > size.mobile && setHover(false)}
      onTouchStart={() => windowWidth && windowWidth <= size.mobile && setHover(true)}
      onTouchEnd={() => windowWidth && windowWidth <= size.mobile && setHover(false)}
    >
      <Text
        $color={color}
      >
        {text}
      </Text>
      {icon &&
        <IconWrapper
          $isText={!!text}
        >
          <Icon
            icon={icon}
            iconColor={hover && hoverIconColor ? hoverIconColor : iconColor}
            width={"22px"}
            height={"22px"}
          />
        </IconWrapper>
      }
    </Wrapper>

  if (to) {
    return (
      <Link
        to={to}
        style={{textDecoration: "none", display: "inline-block"}}
      >
        {ButtonComponent}
      </Link>
    )
  }

  return ButtonComponent
}

const Text = styled(H4)<{
  $color?: string;
}>`
  color: ${props => props.$color || "initial"};
  white-space: nowrap;
`


const Wrapper = styled.button<{
  $background?: string;
  $border?: string;
  $borderColor?: string;
  $color?: string;
  margin?: string;
  $hoverBackground?: string;
  $hoverBorderColor?: string;
  $hoverColor?: string;
}>`
  @media (${device.mobile}) {
    height: 44px;
    padding: 0 10px;
  }
  height: 50px;
  width: fit-content;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 15px;
  border: 1px solid ${props => props.$borderColor || accentBlack};
  border-radius: 10px;
  background: ${props => props.$background || "transparent"};
  margin: ${props => props.margin || "0"};
  cursor: pointer;
  
  @media (hover: none) {
    &:active {
      background: ${props => props.$hoverBackground || props.$background || backgroundWhite};
      border: 1px solid ${props => props.$hoverBorderColor || props.$borderColor || accentBlack};
    };
    &:active ${Text} {
      color: ${props => props.$hoverColor || props.$color || textBlack};
    };
  };
  
  @media (hover: hover) {
    &:hover {
      background: ${props => props.$hoverBackground || props.$background || backgroundWhite};
      border: 1px solid ${props => props.$hoverBorderColor || props.$borderColor || accentBlack};
    };
    &:hover ${Text} {
      color: ${props => props.$hoverColor || props.$color || textBlack};
    };
  }
`

const IconWrapper = styled.div<{
  $isText: boolean;
}>`
  margin: ${props => props.$isText ? "0 0 0 10px" : "0"};
  height: 22px;
`


