import styled from "styled-components";
import {paragraphs} from "./typography";
import {device} from "./breakpoints";

const {
  paragraph1,
  paragraph1m,
  paragraph2,
  paragraph3,
} = paragraphs


// PARAGRAPH LARGE
export const Paragraph1 = styled.p`
  @media (${device.mobile}) {
    font-size: ${paragraph1m.fontSize};
    line-height: ${paragraph1m.lineHeight};
  };
  font-size: ${paragraph1.fontSize};
  line-height: ${paragraph1.lineHeight};
  font-weight: ${paragraph1.fontWeight};
  margin: ${paragraph1.margin};
  color: ${paragraph1.color};
`
export const Paragraph2 = styled.p`
  font-size: ${paragraph2.fontSize};
  line-height: ${paragraph2.lineHeight};
  font-weight: ${paragraph2.fontWeight};
  margin: ${paragraph2.margin};
  color: ${paragraph2.color};
`

export const Paragraph3 = styled.p`
  font-size: ${paragraph3.fontSize};
  line-height: ${paragraph3.lineHeight};
  font-weight: ${paragraph3.fontWeight};
  margin: ${paragraph3.margin};
  color: ${paragraph3.color};
`


