import React, {useEffect, useState} from 'react';
import styled from 'styled-components'
import {H4, H3} from "./reusable/styling/headers";
import {fontWeights} from "./reusable/styling/typography";
import {accentColors, backgroundColors} from "./reusable/styling/colorPalette";
import {useLocation, Link} from "react-router-dom";
import Socials from "./reusable/Socials";
import {device} from "./reusable/styling/breakpoints";
import Icon from "./reusable/Icon";
import NotificationComponent from "./Notification";
import notification from "../notification.json"


const {
  accentRed
} = accentColors

const {
  backgroundWhite
} = backgroundColors

export type Modules = {
  route: string,
  text: string,
}

type HeaderProps = {}

export default function Header(props: HeaderProps): JSX.Element {

  const {
    text,
    active
  } = notification

  const isActive = active === "true"

  const location = useLocation()
  const [menuOpen, setMenuOpen] = React.useState(false)
  const [nHeight, setNHeight] = useState(0)

  useEffect(() => {
    const notificationElement = document.getElementById("notificationWrapper")
    if (notificationElement) {
      setNHeight(notificationElement.getBoundingClientRect().height)
    }
  }, []);

  const pathname = location.pathname.split("/")[1]

  const modules = [
    {
      route: "domov",
      text: "Domov",
      index: true
    },
    {
      route: "nadzori",
      text: "Nadzori",
    },
    {
      route: "gradnje",
      text: "Gradnje",
    },
    {
      route: "vizitka",
      text: "Vizitka",
    }
  ]

  function handleMenuButtonClick(state: boolean) {
    const element = document.body
    if (element) {
      if (state) {
        element.style.overflow = "hidden"
      } else {
        element.style.overflow = "scroll"
      }
    }
    setMenuOpen(state)
  }

  return (
    <Wrapper
      $menuopen={menuOpen}
      $isActive={isActive}
      $height={nHeight}
    >
      <NotificationComponent
        text={text}
        active={isActive}
        isMenuOpen={menuOpen}
        nHeight={nHeight}
      />
      <InnerWrapper>
        <Link
          to={"/"}
        >
          <Logo
            src={"/logo.svg"}
          />
        </Link>
        <MenuButton
          onClick={() => handleMenuButtonClick(!menuOpen)}
        >
          <Icon
            icon={menuOpen ? "close-circle" : "menu"}
            width={"30px"}
            height={"30px"}
          />
        </MenuButton>

        <NavigationWrapper>
          <Navigation>
            {modules.map(({route, text, index}) =>
              <NavigationButton
                key={route}
                to={`/${route}`}
                $active={pathname === route || (index && pathname === "")}
              >
                <H4>
                  {text}
                </H4>
                <Underline
                  $active={pathname === route || (index && pathname === "")}
                />
              </NavigationButton>
            )}
          </Navigation>
          <Socials/>
        </NavigationWrapper>
      </InnerWrapper>
      <MenuWrapper
        $menuopen={menuOpen}
      >
        <Navigation>
          {modules.map(({route, text, index}) =>
            <NavigationButton
              key={route}
              to={`/${route}`}
              $active={pathname === route || (index && pathname === "")}
              onClick={() => handleMenuButtonClick(false)}
            >
              <MobileTextWrapper>
                {text}
              </MobileTextWrapper>
              <Underline
                $active={pathname === route || (index && pathname === "")}
              />
            </NavigationButton>
          )}
        </Navigation>
        <Socials/>
      </MenuWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  $menuopen?: boolean;
  $isActive: boolean;
  $height: number
}>`
  @media (${device.mobile}) {
    height: ${props => props.$menuopen ? "100vh" : `${props.$height + 70}px`};
    transition: all 0.3s ease-in-out;
    display: block;
    overflow: hidden;
  };
  width: 100%;
  position: sticky;
  top: ${props => props.$menuopen ? "0" : `-${props.$height}px`};
  left: 0;
  background: ${backgroundWhite};
  z-index: 1;
`

const InnerWrapper = styled.div`
  @media (${device.mobile}) {
    align-items: flex-start;
    height: initial;
    padding: 20px;
  };
  padding: 0 70px;
  width: 100%;
  box-sizing: border-box;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MenuWrapper = styled.div<{
  $menuopen?: boolean;
}>`
  @media (${device.mobile}) {
    padding: ${props => props.$menuopen ? "40px 0 0 0" : "0"};
    box-sizing: border-box;
    display: flex;
    max-height: ${props => props.$menuopen ? "calc(100% - 130px)" : "0"};
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  display: none;
`


const Logo = styled.img`
  height: 24px;
`

const MenuButton = styled.div`
  @media (${device.mobile}) {
    display: block;
  }
  display: none;
`

const NavigationWrapper = styled.div`
  @media (${device.mobile}) {
    display: none;
  }
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`

const Navigation = styled.div`
  @media (${device.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 40px 0;
    height: 200px;
  }
  width: initial;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 70px 0 0 ;
  height: 100%;
`

const MobileTextWrapper = styled(H3)`
  font-weight: ${fontWeights.Medium};
`


const NavigationButton = styled(Link)<{
  $active?: boolean;
}>`
  @media (${device.mobile}) {
    height: 30px;
  }
  box-sizing: border-box;
  margin: 0 20px;
  height: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  position: relative;
`

const Underline = styled.div<{
  $active?: boolean;
}>`
  height: 3px;
  background-color: ${accentRed};
  width: ${props => props.$active ? `100%` : "0"};
  transition: width 0.3s ease-in-out;
  position: absolute;
  bottom: 0;
`
