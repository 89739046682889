export const size = {
  mobile: 768,
  tablet: 1024,
  tabletHorizontal: 1366,
  laptop: 1920,
  desktop: 2560,
  desktopL: 3840,
  desktopXL: 7680,
}

export const device = {
  mobile: `max-width: ${size.mobile}px`,
  tablet: `max-width: ${size.tablet}px`,
  tabletHorizontal: `max-width: ${size.tabletHorizontal}px`,
  laptop: `max-width: ${size.laptop}px`,
  desktop: `max-width: ${size.desktop}px`,
  desktopL: `max-width: ${size.desktopL}px`,
  desktopXL: `max-width: ${size.desktopXL}px`,
};