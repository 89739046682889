import React, {useEffect} from 'react';
import styled from 'styled-components'
import {H2, H3, H4} from "../../components/reusable/styling/headers";
import {Paragraph1} from "../../components/reusable/styling/paragraphs";
import Certificates from "../../components/reusable/Certificates";
import {device} from "../../components/reusable/styling/breakpoints";

type indexProps = {}

export default function Vizitka(props: indexProps): JSX.Element {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <VizitkaWrapper>
        <TextWrapper>
          <Title>
            Vizitka
          </Title>
          <SubTitle>
            Graviz d.o.o.
          </SubTitle>
          <CardWrapper>
            <CardTitle>
              Zastopnik
            </CardTitle>
            <Paragraph1> Damjan Hribar, direktor </Paragraph1>
            <Paragraph1> info@graviz.si </Paragraph1>
            <Paragraph1> +386 041 747 851 </Paragraph1>
          </CardWrapper>
          <CardWrapper>
            <CardTitle>
              Naslov
            </CardTitle>
            <Paragraph1> Brode 24H </Paragraph1>
            <Paragraph1> 3305, Vransko </Paragraph1>
          </CardWrapper>
          <CardWrapper>
            <CardTitle>
              Davčna in matična številka
            </CardTitle>
            <Paragraph1> D.Š.: SI84985500 </Paragraph1>
            <Paragraph1> M.Š: 8775532000 </Paragraph1>
          </CardWrapper>
          <CardWrapper>
            <CardTitle>
              TRR
            </CardTitle>
            <Paragraph1> SI56 1010 0005 8914 691 </Paragraph1>
            <Paragraph1> Banka Intesa Sanpaolo d.d. </Paragraph1>
          </CardWrapper>
        </TextWrapper>
        <ImageWrapper>
          <Image
            src={"./images/vizitka/ekipa.jpeg"}
            alt={"Slika Graviz ekipe"}
          />
        </ImageWrapper>
      </VizitkaWrapper>
      <H3>
        Naši certifikati
      </H3>
      <Certificates/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  @media (${device.tablet}) {
    padding: 40px 40px 0 40px;
  };
  @media (${device.mobile}) {
    padding: 20px 20px 0 20px;
  };
  max-width: 1400px;
  width: 100%;
  padding: 60px 70px 0 70px;
  box-sizing: border-box;
  margin: 0 auto 100px auto;
`
const VizitkaWrapper = styled.div`
  @media (${device.mobile}) {
    flex-direction: column;
  };
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 0 60px 0;
`

const TextWrapper = styled.div`
  @media (${device.tablet}) {
    max-width: 243px;
  };
  max-width: 393px;
  width: 100%;
`

const ImageWrapper = styled.div`
  @media (${device.tablet}) {
    max-height: 443px;
  };
  @media (${device.mobile}) {
    max-height: 243px;
    margin: 40px 0 0 0;
  };
  display: block;
  margin: 0;
  max-width: 827px;
  width: 100%;
  max-height: 543px;
  height: 543px;
  background: lightgray;
  border-radius: 10px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`

const Title = styled(H2)``
const SubTitle = styled(H3)`
  margin: 40px 0 40px 0;
`

const CardTitle = styled(H4)`
  margin: 0 0 10px 0;
`

const CardWrapper = styled.div`
  margin: 20px 0 0 0;
`


