import React from 'react';
import styled from 'styled-components'
import {H2, H3} from "../reusable/styling/headers";
import Button from "../../components/reusable/Button";
import Markdown from 'react-markdown'
import Gallery from "../../components/reusable/Gallery";
import {accentColors, backgroundColors, textColors} from "../reusable/styling/colorPalette";
import {ProjectType} from "../../views/projekt";
import AsyncImage from "../reusable/AsyncImage";
import convertFileNameToMin from "../../utils/convertFileNameToMin";

// @ts-ignore
import styles from './Markdown.module.css';

const {
  backgroundRed,
} = backgroundColors

const {
  accentWhite
} = accentColors

const {
  textWhite
} = textColors

type ProjectProps = {
  project: ProjectType;
  handleFullScreenClick?(item: string): void;
  groupSize: number;
  displayButton?: boolean;
  lazyLoadImage?: boolean
}

export default function Project(props: ProjectProps): JSX.Element {

  const {
    project,
    handleFullScreenClick,
    groupSize,
    displayButton = true,
    lazyLoadImage = true
  } = props


  return (
    <Wrapper>
      <RowWrapper>
        <Title>
          {project.title}
        </Title>
        {displayButton &&
          <Button
            text={"Pošlji povpraševanje"}
            icon={"sms"}
            to={"mailto:info@graviz.si"}
            background={backgroundRed}
            color={textWhite}
            iconColor={accentWhite}
            borderColor={"transparent"}
          />
        }
      </RowWrapper>
      <MarkdownComponent
        className={styles.reactMarkDown}
      >
        {project.description}
      </MarkdownComponent>
      <H3>
        Galerija slik
      </H3>
      <Gallery
        items={project.gallery}
        groupSize={groupSize}
        callback={(item, index) =>
          lazyLoadImage ?
            <ImageWrapper
              key={index}
              onClick={() => handleFullScreenClick && handleFullScreenClick(item)}
            >
              <AsyncImage
                id={`ProjectImage-${item}-${index}`}
                imagePath={item}
                minImagePath={`/images/uploadsmin/${convertFileNameToMin(item)}`}
              />
            </ImageWrapper>
          :
            <Image
              key={index}
              src={item}
              onClick={() => handleFullScreenClick && handleFullScreenClick(item)}
            />
        }
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
`


const Title = styled(H2)`
`

const RowWrapper = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
  margin: 20px 0 0 0;
`

const MarkdownComponent = styled(Markdown)`
  margin: 40px 0 60px 0;
`

const ImageWrapper = styled.div`
  height: 220px;
  width: 285px;
  border-radius: 10px;
  cursor: pointer;
  overflow: hidden;
`

const Image = styled.img`
  height: 220px;
  width: 285px;
  border-radius: 10px;
  cursor: pointer;
`
