import React, {useEffect} from 'react';
import styled from 'styled-components'
import Button from "./reusable/Button";
import {H1} from "./reusable/styling/headers";
import {backgroundColors, textColors, accentColors} from "./reusable/styling/colorPalette";
import {device} from "./reusable/styling/breakpoints";
import AsyncImage from "./reusable/AsyncImage";

const {
  backgroundWhite,
  backgroundBlack,
  backgroundRed
} = backgroundColors

const {
   textWhite,
} = textColors

const {
  accentWhite
} = accentColors


export type CarouselItemType = {
  title: string,
  category: "Gradnje" | "Nadzori",
  imageMin: string,
  image: string,
  link: string,

}

export default function Carousel(): JSX.Element {

  const carouselItems: CarouselItemType[] = [
    {
      title: 'Gradnja montažnih hiš',
      category: 'Gradnje',
      imageMin: 'gradnja 1-min.jpg',
      image: 'gradnja 1.jpg',
      link: '/gradnje'
    },
    {
      title: 'Od ideje do izvedbe',
      category: 'Gradnje',
      imageMin: 'gradnja 2-min.jpg',
      image: 'gradnja 2.jpg',
      link: '/gradnje'
    },
    {
      title: 'Investicijsko vodenje projektov',
      category: 'Nadzori',
      imageMin: 'nadzor 1-min.jpg',
      image: 'nadzor 1.jpg',
      link: '/nadzori'
    },
    {
      title: 'Strokovni nadzori nad gradnjo',
      category: 'Nadzori',
      imageMin: 'nadzor 2-min.jpg',
      image: 'nadzor 2.jpg',
      link: '/nadzori'
    },
    {
      title: 'Gradnja montažnih hiš',
      category: 'Gradnje',
      imageMin: 'gradnja 1-min.jpg',
      image: 'gradnja 1.jpg',
      link: '/gradnje'
    },
  ]

  const [currentItem, setCurrentItem] = React.useState<CarouselItemType>(carouselItems[0])
  const [scrollProgress, setScrollProgress] = React.useState<number>(0)

  useEffect(() => {
    const interval = window.setInterval(() => {
      scrollToNext()
    }, 3500)

    return () => {
      window.clearInterval(interval)
    }
  })


  function scrollToNext() {
    const carousel = document.getElementById("carousel")
    if (!carousel) return
    const {
      width,
    } = carousel.getBoundingClientRect()
    const scrollPosition = carousel.scrollLeft
    const itemIndex = Math.round(scrollPosition / width)
    const nextItemIndex = itemIndex + 1
    const nextItem = carouselItems[nextItemIndex]
    if (!nextItem) {
      carousel.scrollTo({
        left: 0,
        // @ts-ignore
        behavior: "instant"
      })
    } else {
      carousel.scrollTo({
        left: nextItemIndex * width,
        behavior: "smooth"
      })
    }

  }

  function scrollTo(item: CarouselItemType) {
    const carousel = document.getElementById("carousel")
    if (!carousel) return
    const {
      width,
    } = carousel.getBoundingClientRect()
    const itemIndex = carouselItems.findIndex((carouselItem) => carouselItem === item)
    if (itemIndex === -1) return
    carousel.scrollTo({
      left: itemIndex * width,
      behavior: "smooth"
    })
  }

  // console.log(currentItem)

  return (
    <Wrapper>
      <Images
        id={"carousel"}
        onScroll={(e) => {
          const element = document.getElementById("carousel")
          if (!element) return
          const {
            width,
          } = element.getBoundingClientRect()
          const scrollPosition = e.currentTarget.scrollLeft
          const itemIndex = Math.round(scrollPosition / width)
          setCurrentItem(carouselItems[itemIndex])
          setScrollProgress(scrollPosition / width - Math.floor(scrollPosition / width))

        }}
      >
        {carouselItems.map((item, index) =>
          <CarouselItem>
            <AsyncImage
              key={index}
              id={`carouselImage${index}`}
              imagePath={`./images/naslovnice/${item.image}`}
              minImagePath={`./images/naslovnice/${item.imageMin}`}
            />
          </CarouselItem>
        )}
      </Images>
      <InnerWrapper>
        <Title
          $scrollProgress={scrollProgress}
        >
          {currentItem.title}
        </Title>
        <Button
          text={currentItem.category === "Gradnje" ? "Klikni za gradnje" : "Klikni za nadzore"}
          background={backgroundRed}
          color={textWhite}
          iconColor={accentWhite}
          icon={"arrow-square-right"}
          borderColor={"transparent"}
          to={currentItem.link}
        />
      </InnerWrapper>
      <DotsWrapper>
        {carouselItems.filter((item, index) => index < carouselItems.length - 1).map((item, index) => {
          return (
            <Dot
              key={index}
              $active={JSON.stringify(item) === JSON.stringify(currentItem)}
              onClick={() => {
                scrollTo(item)
              }}
            />
          )
        })}
      </DotsWrapper>
    </Wrapper>
  )
}


const Wrapper = styled.div`
  @media (${device.desktopXL}) {
    height: 1600px;
    max-height: 1600px;
  }
  @media (${device.desktopL}) {
    height: 1200px;
    max-height: 1200px;
  }
  @media (${device.desktop}) {
    height: 900px;
    max-height: 900px;
  }
  @media (${device.laptop}) {
    max-height: 660px;
    height: 660px;
  }
  @media (${device.tabletHorizontal}) {
    height: 460px;
  }
  @media (${device.mobile}) {
    height: 260px;
    padding: 0 20px;
  }
  max-height: 660px;
  height: 660px;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  padding: 0 70px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;

`

const CarouselItem = styled.div`
  height: 100%;
  min-width: 100vw;
  scroll-snap-align: start;
`

const Images = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  position: absolute;
  top: 0;
  left: 0;
  &::-webkit-scrollbar {
    display: none;
  }
`

const InnerWrapper = styled.div`
  @media (${device.mobile}) {
    padding: 0;
  }
  @media (${device.tabletHorizontal}) {
    padding: 0;
  }
  position: relative;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 70px;
`

const Title = styled(H1).attrs<{
  $scrollProgress: number
}>(props => ({
  style: {
    opacity: props.$scrollProgress < 0.5 ? 1 - props.$scrollProgress * 2 : (props.$scrollProgress - 0.5) * 2
  }
}))`
  @media (${device.mobile}) {
    max-width: 180px;
    min-height: 50px;
    margin: 0 0 20px 0;
    text-shadow: -1px -1px 15px ${backgroundBlack}, 1px -1px 15px ${backgroundBlack}, -1px 1px 15px ${backgroundBlack}, 1px 1px 15px ${backgroundBlack};
  }
  max-width: 440px;
  width: 100%;
  min-height: 150px;
  margin: 0 0 40px 0;
  color: ${textWhite};
  text-shadow: -1px -1px 50px ${backgroundBlack}, 1px -1px 50px ${backgroundBlack}, -1px 1px 50px ${backgroundBlack}, 1px 1px 50px ${backgroundBlack};
`

const DotsWrapper = styled.div`
  @media (${device.mobile}) {
    display: none;
  }
  display: flex;
  align-items: center;
  width: fit-content;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translateX(-50%);
`

const Dot = styled.div<{
  $active: boolean
}>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${props => props.$active ? backgroundBlack : backgroundWhite};
  border: 1px solid ${backgroundBlack};
  margin: 0 5px;
  cursor: pointer;
`
